import React from 'react';
import PropTypes from 'prop-types';

// From https://github.com/ajwann/svg-loaders-react

const size = 32;
const SpinningCircles = ({}: any) => (
  <svg width={size} height={size} viewBox={`0 0 58 58`}>
    <g
      transform="translate(2 1)"
      stroke="#aa1418"
      strokeWidth={1.5}
      fill="#aa1418"
      fillRule="evenodd"
    >
      <circle cx={42.601} cy={11.462} r={5}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="1;0;0;0;0;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={49.063} cy={27.063} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;1;0;0;0;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={42.601} cy={42.663} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;1;0;0;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={27} cy={49.125} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;1;0;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={11.399} cy={42.663} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;0;1;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={4.938} cy={27.063} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;0;0;1;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={11.399} cy={11.462} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;0;0;0;1;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={27} cy={5} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;0;0;0;0;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </g>
  </svg>
);

SpinningCircles.propTypes = {
  className: PropTypes.string,
};

SpinningCircles.defaultProps = {
  className: undefined,
};

export { SpinningCircles };