import styled from '@emotion/styled';
import {csvParse} from 'd3-dsv';
import { viewport } from '@mapbox/geo-viewport';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import './App.css';
import useFetch from 'react-fetch-hook';
import NoScrollContainer from './NoScrollContainer';
import {FeatureCollection} from 'geojson';
import MapContainer from './MapContainer';
import { DeckGL } from '@deck.gl/react';
import {MapController, MapView} from '@deck.gl/core';
import {
  StaticMap,
  _MapContext as MapContext,
  SVGOverlay,
  ViewState,
  ViewStateChangeInfo,
} from 'react-map-gl';
import {GeoJsonLayer, ScatterplotLayer} from '@deck.gl/layers';
import {count, descending, max, sum} from 'd3-array';
import {scaleSqrt} from 'd3-scale';
import {Topology} from 'topojson-specification';
import {feature} from 'topojson-client';
import LabelsOverlay from './LabelsOverlay';
import {useWindowSize} from './useWindowSize';
import Flatbush from 'flatbush';
import WebMercatorViewport from 'viewport-mercator-project';
import {usePopper} from 'react-popper';
import {createPortal} from 'react-dom';
import {SpinningCircles} from './SpinningCircles';
import fullscreenIcon from './fullscreen.svg';
const accessToken = process.env.REACT_APP_MapboxAccessToken;
const mapboxMapStyle = process.env.REACT_APP_StyleUrl;

// TODO проекция
// https://github.com/Kreozot/russian-geo-data/blob/master/map.svg

const SHOW_BASE_MAP = false;
const DARK_MODE = false;
export const MIN_ZOOM_LEVEL = 0;
export const MAX_ZOOM_LEVEL = 20;
export const MIN_PITCH = 0;
export const MAX_PITCH = +60;

const BASE_URL = `https://docs.google.com/spreadsheets/d`;
const SPREADSHEET_KEY = '1GChRWHk6N1SY8NedsPc3kYpkvOkKOabmIkM3gBx5ChM';

const makeSheetQueryUrl = (sheet: string) =>
  `${BASE_URL}/${SPREADSHEET_KEY}/gviz/tq?tqx=out:csv&sheet=${encodeURIComponent(sheet)}`;


const Outer = styled(NoScrollContainer)({
  display: 'flex',
  flexDirection: 'column',
  color: '#aa1418',
});

const FullscreenButton = styled.a`
  border: 1px solid rgba(170, 20, 24, 0.15);
  box-shadow: 0 0 2px rgba(170, 20, 24, 0.25);
  cursor: pointer;
  background: white;
  border-radius: 4px;
  padding: 7px 7px 4px 7px;
`;

const CenterBlock = styled.div`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;

const DatesList = styled.div`
  & > *+* { margin-left: 1em; }
`;

const DateLink = styled.div<{selected:boolean}>(({ selected}) => `
  display: inline-block; 
  cursor: pointer;
  ${selected ? `font-weight: bold; text-decoration: underline;` : ''}
  &:hover{ text-decoration: underline; } 
`);

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  &>*+* { margin-top: 0.5em; }
  text-align: center;
  & > .title {
    font-weight: bold;
    font-size: 1.2em;
  }
`;

const LegendBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid rgba(170, 20, 24, 0.15);
  box-shadow: 0 0 2px rgba(170, 20, 24, 0.25);
  & a, a:visited {
    color: #aa1418;
  }
`;

const Loading = styled(LegendBox)({
  display: 'flex',
  alignSelf: 'center',
  fontSize: '1 rem'
});


const TotalCount = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  //color: rgb(270, 120, 124);
  color: #aa1418;
  //text-shadow: 1px 1px 2px #aa1418,-1px 1px 2px #aa1418,-1px -1px 2px #aa1418,1px -1px 2px #aa1418;
`;

const Timestamp = styled.div`
  font-size: 0.6rem;
  height: 1em;
`;

const Credits = styled.div`
  font-size: 0.6rem;
  margin-top: 1em;
  line-height: 1.5em;
`;

const TopLegend = styled(LegendBox)`
  max-width: 230px;
  right: 20px;
`;

const BottomLegend = styled(LegendBox)`
  text-align: center;
  max-width: 140px;
  & > *+* { margin-top: 5px; }
`;

const getBoxStyle = () => `
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  font-size: 11px;
  box-shadow: 0 0 5px rgba(170, 20, 24, 0.5); 
`;

export interface AbsoluteProps {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}


export const Absolute = styled.div<AbsoluteProps>(
  ({ top, left, right, bottom }: AbsoluteProps) => `
  position: absolute;
  ${top != null ? `top: ${top}px;` : ''}
  ${left != null ? `left: ${left}px;` : ''}
  ${right != null ? `right: ${right}px;` : ''}
  ${bottom != null ? `bottom: ${bottom}px;` : ''}
`
);


export const Box = styled(Absolute)<{}>(getBoxStyle);

const TooltipBox = styled(Box)({
  pointerEvents: 'none',
  color: '#aa1418',
  padding: 10,
  maxWidth: 120,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  '&>* + *': {
    marginTop: 5,
  },
});


const CONTROLLER_OPTIONS = {
  type: MapController,
  doubleClickZoom: false,
  dragRotate: false,
  touchRotate: false,
  minZoom: 0,
  maxZoom: 15,
};


function getInitialViewport(bbox: [number, number, number, number]) {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const {
    center: [longitude, latitude],
    zoom,
  } = viewport(bbox, [width, height], undefined, undefined, 512, true);
  return {
    width,
    height,
    longitude,
    latitude,
    zoom,
    minZoom: MIN_ZOOM_LEVEL,
    maxZoom: MAX_ZOOM_LEVEL,
    minPitch: MIN_PITCH,
    maxPitch: MAX_PITCH,
    bearing: 0,
    pitch: 0,
    altitude: 1.5,
  };
}


const INITIAL_VIEWSTATE = getInitialViewport([16.4,41.2,170.0,67.8]);



const MapOuter = styled.div({
  display: 'flex',
  flexGrow: 1,
  position: 'relative',
});

const MouseMoveContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; left: 0;
`;

const DeckGLOuter = styled.div<{
  darkMode: boolean;
  baseMapOpacity: number;
  cursor: 'crosshair' | 'pointer' | undefined;
}>(
  ({ cursor, baseMapOpacity, darkMode }) => `
  & #deckgl-overlay {
    mix-blend-mode: ${darkMode ? 'screen' : 'multiply'};
  }
  & .mapboxgl-map {
    opacity: ${baseMapOpacity}
  }
  ${cursor != null ? `& #view-default-view { cursor: ${cursor}; }` : ''},
`
);


export type DateRecord = {
  date: string;
  sheet: string;
  lastUpdated: string;
  latest: string;
}

export type Location = {
  name: string;
  lat: number;
  lon: number;
}

export type CountItem = {
  name: string;
  detained: number;
}

export type Item = Location & CountItem;

const SHAPE_LINE_COLOR = [150, 100, 100];
const SHAPE_FILL_COLOR = [230, 220, 220];
const CIRCLE_LINE_COLOR = [255,255,255];
const CIRCLE_LINE_COLOR__HIGHLIGHTED = [255,153,44];
const CIRCLE_FILL_COLOR = [170, 20, 24];
const CIRCLE_FILL_COLOR__MUTED = [170, 170, 170];


function App() {
  const [viewport, setViewport] = useState<ViewState>(INITIAL_VIEWSTATE);
  const [ tooltip, setTooltip ] = useState<any>();
  const [ selectedDateRecord, setSelectedDateRecord ] = useState<DateRecord>();
  // const [ hoverItem, setHoverItem ] = useState<Item>();
  const handleViewStateChange = ({ viewState }: ViewStateChangeInfo) => {
    setViewport(viewState);
    // setSelectedLocation(null);
    setTooltip(undefined);
  };
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize)  // not sure why this doesn't fire from react-map-gl
    setViewport({
      ...viewport,
      ...windowSize,
    });
  },[windowSize?.width, windowSize?.height]);
  const fetchShapes = useFetch(`/shapes.topo.json`);
  const geoJsonFeatures = useMemo(() => {
    const topology = fetchShapes.data as Topology<any>;
    if (topology) {
      return feature<any>(topology, topology.objects['zones']) as any as FeatureCollection;
    }
    return undefined;
  }, [fetchShapes.data]);

  const layers: any[] = [];
  const fetchDates = useFetch<DateRecord[]>(makeSheetQueryUrl('dates'), {
    formatter: (response) =>
      response.text().then((text) =>
        csvParse(text) as any as DateRecord[]
      ),
  });
  const fetchLocations = useFetch<Location[]>(makeSheetQueryUrl('locations'), {
    formatter: (response) =>
      response.text().then((text) =>
        csvParse(text, (row: any) => ({
          name: row.id,
          lat: +row.lat,
          lon: +row.lon,
        })) as any as Location[]
      ),
  });
  useEffect(() => {
    if (fetchDates.data) {
      let selected = null;
      const date = document.location.search.substr(1);
      if (date.length > 0) {
        selected = fetchDates.data.find(d => d.sheet === date);
      }
      if (!selected) {
        selected = fetchDates.data[fetchDates.data.length - 1];
      }
      setSelectedDateRecord(selected);
    }
  }, [fetchDates.data]);
  const fetchData = useFetch<CountItem[]>(
    selectedDateRecord
      ? (selectedDateRecord.latest === 'yes' ? '/.netlify/functions/latest' : makeSheetQueryUrl(selectedDateRecord.sheet))
      : '', {
    formatter: (response) =>
      response.text().then((text) =>
        csvParse(text, (row: any) => ({
          name: row.id,
          detained: +row.detained,
        })) as any as CountItem[]
      ),
    depends: [selectedDateRecord],
  });
  const countsData = useMemo(() => {
    if (!fetchData.data || !fetchLocations.data) return undefined;
    const locationsByName = fetchLocations.data
      .reduce((m,d) => {m.set(d.name,d); return m;}, new Map<string,Location>());
    let sortedCounts = fetchData.data
      .sort((a,b)=> descending(a.detained, b.detained));
    const data: Item[] = [];
    for (const d of sortedCounts) {
      const loc = locationsByName.get(d.name);
      if (loc) {
        data.push({
          ...loc,
          ...d,
        });
      } else {
        console.warn(`No location by name '${d.name}'`)
      }
    }
    return data;
  }, [fetchData.data, fetchLocations.data]);

  const maxCount = useMemo(() => {
    if (!countsData) return undefined;
    return max(countsData, d=> d.detained)
  }, [countsData]);

  const totalCount = useMemo(() => {
    if (!fetchData.data) return undefined;
    return sum(fetchData.data, d=> d.detained)
  }, [fetchData.data]);

  const sizeScale = useMemo(() => {
    if (!maxCount) return undefined;
    return scaleSqrt().range([0,80]).domain([0,5000]);
  }, [maxCount]);


  const mercator = useMemo(() => new WebMercatorViewport(viewport), [viewport]);

  const spatialIndex = useMemo(() => {
    if (countsData && countsData.length > 0) {
      const index = new Flatbush(countsData.length);
      for (const d of countsData) {
        const [x,y]= mercator.project([d.lon, d.lat]);
        index.add(x,y,x,y);
      }
      index.finish();
      return index;
    }
    return undefined;
  }, [countsData, mercator]);

  if (geoJsonFeatures != null) {
    layers.push(
      new GeoJsonLayer({
        id: 'shapes',
        data: geoJsonFeatures,
        stroked: true,
        filled: true,
        opacity: 1.0,
        lineWidthUnits: 'pixels',
        getLineWidth: 0.5,
        getLineColor: SHAPE_LINE_COLOR,
        getFillColor: SHAPE_FILL_COLOR,
        pickable: false,
      }),
    );
  }

  const setTooltipItem = (d: Item | undefined) => {
    if (!d || !sizeScale) {
      setTooltip(undefined);
      return;
    }
    if (tooltip?.object === d) return;
    const [x,y]= mercator.project([d.lon, d.lat]);
    const r = sizeScale(d.detained);
    // const dx = Math.cos(Math.PI/4) * r + 2;
    // const dy = Math.sin(Math.PI/4) * r + 2;
    const dx = r + 4;
    const dy = r + 4;
    setTooltip({
        object: d,
        x: x + dx,
        y: y + dy,
        virtualReference: {
          getBoundingClientRect() {
            return {
              top: y - dy,
              left: x - dx,
              bottom: y + dy,
              right: x + dx,
              width: dx * 2,
              height: dy * 2,
            };
          }
        },
      }
    );
  };

  if (fetchData.data && sizeScale) {
    layers.push(
      new ScatterplotLayer({
        id: 'scatterplot-layer',
        data: countsData,
        pickable: true,
        // opacity: 0.8,
        stroked: true,
        filled: true,
        radiusUnits: 'pixels',
        // radiusMinPixels: 1,
        // radiusMaxPixels: 100,
        lineWidthMinPixels: 1,
        lineWidthUnits: 'pixels',
        getPosition: (d: Item) => [d.lon, d.lat],
        getRadius: (d: Item) => sizeScale(d.detained),
        lineWidthScale: 2,
        getLineColor: (d: Item) => {
          if (tooltip && tooltip.object === d) {
            return CIRCLE_LINE_COLOR__HIGHLIGHTED;
          }
          return CIRCLE_LINE_COLOR;
        },
        getLineWidth: (d: Item) => {
          if (tooltip && tooltip.object === d) {
            return 1;
          }
          return 0.25;
        },
        getFillColor: CIRCLE_FILL_COLOR,
        // getFillColor: (d: Item) => {
        //   if (tooltip) {
        //     if (tooltip.object !== d) {
        //       return CIRCLE_FILL_COLOR__MUTED;
        //     }
        //   }
        //   return CIRCLE_FILL_COLOR;
        // },
        // autoHighlight: true,
        // highlightColor: [65, 158, 235],
        onHover: (info: any) => {
          setTooltipItem(info ? info.object : undefined);
        },
        updateTriggers: {
          // getFillColor: [tooltip]
          getLineColor: [tooltip],
          getLineWidth: [tooltip]
        }
      })
    );
  }

  const handleMouseMove = (evt: MouseEvent) => {
    let item: Item | undefined = undefined;
    if (spatialIndex && countsData && sizeScale) {
      const found = spatialIndex.neighbors(
        evt.clientX, evt.clientY, 1, 50
      );
      if (found.length > 0) {
        item = countsData[found[0]];
        // setHoverItem(item);
      }
    }
    setTooltipItem(item);
  }

  const [popperElement, setPopperElement] = React.useState(null);
  // const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(tooltip?.virtualReference, popperElement,
    // { modifiers: [{ name: 'arrow', options: { element: arrowElement } }],}
  );

  const outerRef = useRef<HTMLDivElement>(null);
  const isEmbedded = useMemo(() => {
    try {
      return window.self !== window.top;
    } catch (err) {}
    return false;
  }, []);

  // const [isFullScreen, setFullScreen] = useState(false);
  // const handleFullScreen = () => {
  //   const outer = outerRef.current;
  //   if (outer) {
  //     if (isFullScreen) {
  //       // @ts-ignore
  //       if (document.exitFullscreen) {
  //         document.exitFullscreen().then(() => setFullScreen(false));
  //       }
  //     } else {
  //       if (outer.requestFullscreen) {
  //         outer.requestFullscreen().then(() => setFullScreen(true));;
  //       }
  //     }
  //   }
  // };


  return (
    <Outer ref={outerRef}>
      <MapOuter
      >
        <MapContainer>
          <DeckGLOuter
            darkMode={DARK_MODE}
            baseMapOpacity={100}
            cursor="pointer"
          >
            <MouseMoveContainer
              // @ts-ignore
              onMouseMove={handleMouseMove}
              onMouseLeave={() => setTooltip(undefined)}
            >
              <DeckGL
                repeat={true}
                controller={CONTROLLER_OPTIONS}
                layers={layers}
                onViewStateChange={handleViewStateChange}
                views={[new MapView({id: 'map', repeat: true})]}
                viewState={viewport}
                ContextProvider={MapContext.Provider}
                parameters={{
                  clearColor:
                    DARK_MODE ? [0, 0, 0, 1] : [255, 255, 255, 1],
                }}
              >
                <LabelsOverlay
                  sizeScale={sizeScale}
                  countsData={countsData}
                  viewport={viewport}
                />
                {SHOW_BASE_MAP && <StaticMap
                  mapboxApiAccessToken={accessToken}
                  mapStyle={mapboxMapStyle}
                  width="100%"
                  height="100%"
                />
                }
              </DeckGL>
            </MouseMoveContainer>
            {tooltip?.object &&
            createPortal(<TooltipBox
                // @ts-ignore
                ref={setPopperElement}
                style={styles.popper} {...attributes.popper}
                // top={tooltip.y} left={tooltip.x}
                className="tooltip"
              >
                <div>
                  {tooltip.object.name}
                </div>
                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                  {tooltip.object.detained}
                </div>
                {/*<div className="arrow"*/}
                {/*  // @ts-ignore*/}
                {/*     ref={setArrowElement} style={styles.arrow} />*/}
              </TooltipBox>,
              document.body)
            }

            {(fetchDates.isLoading || fetchData.isLoading || fetchLocations.isLoading)
              ? <CenterBlock><Loading>
                {/*<div>Загрузка данных…</div>*/}
                <SpinningCircles/>
              </Loading></CenterBlock>
              : null
            }

            <Absolute top={isEmbedded ? 5 : 15} left={isEmbedded ? 5 : 15}>
            <TopLegend>
              <TitleArea>
                <div className="title">Задержания на акциях</div>
                <div className="subtitle">в поддержку Алексея Навального</div>
                {fetchDates.data ?
                  <DatesList>{
                    fetchDates.data.map(d =>
                      <DateLink
                        key={d.date}
                        onClick={() => {
                          setTooltip(undefined);
                          setSelectedDateRecord(d);
                        }}
                        selected={d.date === selectedDateRecord?.date}
                      >
                        {d.date}
                      </DateLink>
                    )}
                  </DatesList>
                  : null}
                {(fetchDates.error || fetchData.error || fetchLocations.error)
                && <Loading>Простите, произошла ошибка загрузки данных…</Loading>}
              </TitleArea>
            </TopLegend>
            </Absolute>

            {selectedDateRecord && countsData &&
            <Absolute bottom={isEmbedded ? 5 : 15} left={isEmbedded ? 5 : 15}>
              <BottomLegend>
                <div>Всего задержанных</div>
                <TotalCount>{totalCount}</TotalCount>
                <Credits>
                  Данные: <a href="https://ovdinfo.org/" rel="noreferrer" target="_blank">ОВД-Инфо</a>
                  <Timestamp>{selectedDateRecord.lastUpdated ?
                    ` на ${selectedDateRecord.lastUpdated}` : ''}</Timestamp>
                </Credits>
              </BottomLegend>
            </Absolute>
            }

            {isEmbedded &&
            <Absolute bottom={15} right={isEmbedded ? 5 : 15}>
              <FullscreenButton
                title="Во весь экран"
                href="/"
                rel="noreferrer" target="_blank"
              ><img src={fullscreenIcon} width={16} height={16}/></FullscreenButton>
            </Absolute>}

          </DeckGLOuter>
        </MapContainer>
      </MapOuter>
    </Outer>
  );
}

export default App;
